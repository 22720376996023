import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import {
  IoIosTime,
  IoIosPaperPlane,
  IoIosListBox,
  IoMdPulse,
  IoMdOpen,
  IoIosDisc,
  IoIosDesktop,
  IoIosFlash,
  IoIosCheckmarkCircle,
  IoIosSearch,
  IoIosArrowForward,
  IoIosLogIn,
  IoIosSunny,
  IoIosLock,
} from "react-icons/io";
import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import {
  CallToAction,
  LogoScroller,
  ImageGraphic,
  Modal,
} from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";

const CustomerName = styled.h4`
  color: #fff;
  position: absolute;
  bottom: 60px;
  right: 60px;
  text-align: right;
`;

const Recruitment = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Payroll Software for Recruitment Businesses | PayHero"
        description="Super easy, accurate and compliant online payroll coupled with invoicing & timesheet management software will make running your recruitment business easy."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} noPaddingBottom>
          <Row stackGap={80} alignCenter>
            <Box size={50} stackGap={50} centerOnMobile>
              <Box stackGap={20}>
                <h1 className="-lg -primary badge">Payroll for Recruitment</h1>
                <h2 className="h1 -fontLarge">
                  New Zealand’s favourite{" "}
                  <span className="-fontPrimary">
                    back office recruitment software
                  </span>
                </h2>
                <h4>
                  Super easy, accurate and compliant online payroll coupled with
                  contractor management & timesheet software will make running
                  your recruitment business easier than ever.
                </h4>
              </Box>
              <Box stackGap={15}>
                <FlexButtons>
                  <Button className="primary -lg gtm-cta" to="/signup">
                    Try 14 Days Free
                  </Button>
                  <Button
                    className="grey -lg gtm-cta"
                    onClick={toggleModal}
                    atag
                  >
                    Book a Sales Call
                  </Button>
                </FlexButtons>
                <p css={{ color: "#999" }}>
                  Get started in minutes, no credit card required.
                </p>
              </Box>
            </Box>
            <Box size={50} css={{ padding: "40px" }}>
              <ImageGraphic variant="1" />
              <Image
                filename="RecruitmentHero.jpg"
                alt="Payroll for Recruitment Businesses"
                centerImage
                addShadow
                rounded
              />
              <CustomerName>
                <b className="-handWritten">Kerry Gregory,</b>
                <br />{" "}
                <span css={{ fontSize: "1rem" }}>
                  General Manager at Accordant
                </span>
              </CustomerName>
            </Box>
          </Row>
          <LogoScroller>
            <Image
              filename="FlexiTimeRecruitmentCustomers.png"
              alt="PayHero Recruitment Payroll Customers"
              centerImage
            />
          </LogoScroller>
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-textCenter" noPaddingBottom>
          <Row stackGap={40}>
            <Box size={33}>
              <h3>
                <IoIosTime />
                <br />
                Accurate, fast payroll
              </h3>
              <p>
                Generate 100% compliant NZ payroll for temps, contractors and
                your own staff. Give your candidates access to add time &
                expenses, request leave and view payslips and BCI’s.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosPaperPlane />
                <br />
                Buyer created invoices
              </h3>
              <p>
                Contractors can download their own buyer created invoices from
                Invoxy, greatly simplifying their payment process.
              </p>
            </Box>
            <Box size={33}>
              <h3>
                <IoIosListBox />
                <br />
                Powerful reporting
              </h3>
              <p>
                Make decisions based on the metrics and KPIs that drive your
                business. Karmly’s advanced business intelligence platform
                delivers Net Direct Revenue and other custom reports.
              </p>
            </Box>
          </Row>
          <Image
            filename="PayHeroHero_Cropped.png"
            alt="Payroll Software for Recruitment | PayHero"
            centerImage
            addShadow
            maxWidth={1100}
          />
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <h2 className="-textCenter">
            PayHero makes recruitment payroll easy
          </h2>
          <Box stackGap={40}>
            <Row stackGap={40} className="-textCenter">
              <Box size={25}>
                <h3>
                  <IoIosSearch />
                  <br />
                  Payday Filing, sorted now and always
                </h3>
                <p>
                  Enjoy fully automated payday filing where you don’t need to
                  lift a finger. Every time you run a pay, we’ll automatically
                  send your PAYE information to Inland Revenue.
                </p>
              </Box>
              <Box size={25}>
                <h3>
                  <IoIosFlash />
                  <br />
                  Automated public holiday calculations
                </h3>
                <p>
                  PayHero uses timesheet entries to determine whether a public
                  holiday is an otherwise working day for an employee and
                  calculates their entitlements accordingly.
                </p>
              </Box>
              <Box size={25}>
                <h3>
                  <IoIosDisc />
                  <br />
                  Built for payroll compliance
                </h3>
                <p>
                  Don’t let payroll compliance keep you up at night. PayHero
                  handles employee leave in line with the Holidays Act 2003 and
                  the latest government guidance.
                </p>
              </Box>
              <Box size={25}>
                <h3>
                  <IoIosDesktop />
                  <br />
                  Try before you buy
                </h3>
                <p>
                  With a fully featured{" "}
                  <Link to="/signup">free 14 day trial</Link>, you can take
                  PayHero for a risk-free test run before committing.
                </p>
              </Box>
            </Row>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper className="-textCenter" noPaddingBottom>
          <Box stackGap={40}>
            <img
              className="-center"
              src={InvoxyLogo}
              alt="Karmly Integration | PayHero"
              width={220}
            />
            <div>
              <h6>Your middle office, made easy</h6>
              <h2>
                Karmly is a better way to manage your contractors and temps
              </h2>
            </div>
          </Box>
          <Row stackGap={40}>
            <Box size={25}>
              <h3 className="-fontOrange">
                <IoIosTime />
                <br />
                Timesheets
              </h3>
              <p>
                Contractors and temps record their hours and request approval
                online. Admin users can quickly add bulk time.
              </p>
            </Box>
            <Box size={25}>
              <h3 className="-fontOrange">
                <IoIosPaperPlane />
                <br />
                Client Approvals
              </h3>
              <p>
                Clients can view all the details and approve timesheets from an
                email without logging in. We keep a full audit trail of
                approvals.
              </p>
            </Box>
            <Box size={25}>
              <h3 className="-fontOrange">
                <IoIosListBox />
                <br />
                Invoicing
              </h3>
              <p>
                Quickly create and send large batches of invoices to multiple
                clients, all tailored to their exact requirements.
              </p>
            </Box>
            <Box size={25}>
              <h3 className="-fontOrange">
                <IoMdPulse />
                <br />
                Reporting
              </h3>
              <p>
                Get unprecedented visibility into your business performance with
                customisable real time reporting.
              </p>
            </Box>
          </Row>
          <a
            className="link-floating -center"
            href="https://www.karmly.com/recruitment"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discover Karmly <IoMdOpen css={{ top: "1px", left: "3px" }} />
          </a>
          <Image
            filename="InvoxyHero_Cropped.png"
            alt="Karmly | Contractor Management & Timesheet Software"
            maxWidth={1000}
            centerImage
            addShadow
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper className="-textCenter">
          <h2>
            Deeply integrated <Link to="/payroll">payroll</Link> & invoicing
            <br />
            <span className="-fontLight">
              Everything you need to accurately invoice your clients and pay
              your candidates from the moment you make a placement.
            </span>
          </h2>
          <Row stackGap={40}>
            <Box size={25}>
              <h3>
                <IoIosCheckmarkCircle />
                <br />
                Your complete back office
              </h3>
              <p>
                Everything you need to accurately invoice your clients and pay
                your candidates from the moment you make a placement.
              </p>
            </Box>
            <Box size={25}>
              <h3>
                <IoIosLogIn />
                <br />
                Single sign on access
              </h3>
              <p>
                Administrators can easily access and switch between systems
                without having to sign in again.
              </p>
            </Box>
            <Box size={25}>
              <h3>
                <IoIosSunny />
                <br />
                Integrated leave management
              </h3>
              <p>
                Temps and other non-contractors can view leave balances and
                request leave from inside Karmly.
              </p>
            </Box>
            <Box size={25}>
              <h3>
                <IoIosLock />
                <br />
                Take your compliance seriously
              </h3>
              <p>
                PayHero stores annual leave in weeks and reviews recent work
                patterns to ensure you’re fully compliant with the New Zealand
                Holidays Act.
              </p>
            </Box>
          </Row>
          <Image
            filename="Invoxy_PayHero_Fit_White.png"
            alt="Karmly & PayHero Integration"
            centerImage
            maxWidth={1000}
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Recruitment payroll made super easy"
        description="Karmly Integration · Easy Set Up · Cancel Anytime"
        showSalesButton
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default Recruitment;
